import Vue from 'vue';
import VueRouter from 'vue-router';
import ls from 'local-storage';
import store from '../store/index';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      beforeEnter: ifIsAuthenticated && ifIsDefault,
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    // {
    //   path: '/second-page',
    //   name: 'second-page',
    //   component: () => import('@/views/SecondPage.vue'),
    //   meta: {
    //     pageTitle: 'Second Page',
    //     breadcrumb: [
    //       {
    //         text: 'Second Page',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    {
      path: '/reports',
      name: 'reports',
      component: () => import('@/views/reports/Reports.vue'),
      beforeEnter: ifIsAuthenticated && ifIsDefault,
      meta: {
        pageTitle: 'Relatórios',
        breadcrumb: [
          {
            text: 'Relatórios',
            active: true,
          },
        ],
      },
    },

    {
      path: '/training/:id',
      name: 'training',
      component: () => import('@/views/trainings/components/Trainings.vue'),
      beforeEnter: ifIsAuthenticated && ifIsTraining,
      meta: {
        pageTitle: 'Treinamento',
        breadcrumb: [
          {
            text: 'Treinamento',
            active: true,
          },
        ],
      },
    },

    {
      path: '/training-list',
      name: 'training-list',
      component: () => import('@/views/trainings/ListTrainings.vue'),
      beforeEnter: ifIsAuthenticated && ifIsTraining,
      meta: {
        pageTitle: 'Treinamentos',
        breadcrumb: [
          {
            text: 'Treinamentos',
            active: true,
          },
        ],
      },
    },

    {
      path: '/login',
      name: 'login',
      beforeEnter: ifIsNotAuthenticated,
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      beforeEnter: ifIsNotAuthenticated,
      component: () => import('@/views/auth/ForgotPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },

    {
      path: '*',
      redirect: 'error-404',
    },
  ],
});

function ifIsAuthenticated(to, from, next) {
  if (ls.get('t')) {
    next();
  } else {
    next({ name: 'login' });
  }
}

function ifIsTraining(to, from, next) {
  if (store.getters.user &&  store.getters.user.module_training) {
    next();
  } else {
    next({ name: 'home' });
  }
}
function ifIsDefault(to, from, next) {
  if (store.getters.user) {
    if (store.getters.user.module_default) {
      next();
    } else {
      if (
        !store.getters.user.module_default &&
        !store.getters.user.module_training
      ) {
        store.dispatch('logout');
        next({ name: 'login' });
      } else {
        next({ name: 'training-list' });
      }
    }
  } else {
    store.dispatch('logout');
    next({ name: 'login' });
  }
}

function ifIsNotAuthenticated(to, from, next) {
  if (!ls.get('t')) {
    next();
  } else {
    next({ name: 'home' });
  }
}

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
});


// // Usando beforeEach para capturar a rota de origem
// router.beforeEach((to, from, next) => {
//   console.log('De qual rota você veio:', from.name);  // from.name vai te dar o nome da rota de origem
//   console.log('Para qual rota você está indo:', to.name); // to.name te dá o nome da rota de destino
//   next();  // Continuar a navegação
// });

export default router;
